"use client";
import { useEffect } from "react";

function getAnchor(text: string): string {
	return text
		.replace("https://www.youtube.com/watch?v=", "")
		.replace("https://youtube.com/watch?v=", "")
		.replace("www.youtube.com/watch?v=", "")
		.replace("youtube.com/watch?v=", "")
		.replace("https://www.youtu.be/", "")
		.replace("https://youtu.be/", "")
		.replace("www.youtu.be/", "")
		.replace("youtu.be/", "");
}

interface YouTubeProps {
	src: string;
	autoplay?: boolean;
	title?: string;
	article?: boolean;
	modal?: boolean;
}

function YouTube({ src, autoplay = false, title, article, modal }: YouTubeProps) {
	useEffect(() => {
		const cookieValue = getCookie("cookieyes-consent");
		if (cookieValue) {
			const consents = cookieValue.split(",");
			const analyticsConsent = consents.find((c) => c.startsWith("analytics"))?.split(":")[1];
			const advertisementConsent = consents
				.find((c) => c.startsWith("advertisement"))
				?.split(":")[1];

			if (analyticsConsent === "no" || advertisementConsent === "no") {
				const containers = document.querySelectorAll<HTMLElement>(".youtube-cookies");
				if (containers.length > 0) {
					for (let i = 0; i < containers.length; i++) {
						updateContent(containers[i]);
					}
				}
			}
		}

		function updateContent(container: HTMLElement) {
			container.textContent = "";
			const section = document.createElement("section");
			section.setAttribute("aria-live", "assertive");
			section.setAttribute("class", "cookie-alert");
			section.setAttribute("role", "alert");

			const p1 = document.createElement("p");
			p1.textContent =
				"Aby zobaczyć film, konieczna jest zgoda na używanie plików cookie analitycznych oraz reklamowych.";
			section.appendChild(p1);

			const p2 = document.createElement("p");
			p2.textContent =
				"Jeżeli wyrażasz zgodę, prosimy o zaakceptowanie plików cookie, a następnie odświeżenie strony.";
			section.appendChild(p2);

			const button = document.createElement("button");
			button.setAttribute("aria-label", "Zarządzaj zgodami na pliki cookie");
			button.setAttribute("class", "pd-btn pd-btn--primary");
			button.onclick = triggerConsentManager;
			button.textContent = "Zarządzaj zgodami na pliki cookie";
			section.appendChild(button);

			container.appendChild(section);
		}

		function triggerConsentManager() {
			const consentButton = document.querySelector<HTMLElement>(".cky-btn-revisit");
			if (consentButton) {
				consentButton.click();
			} else {
				alert("Przycisk zarządzania zgodami nie jest dostępny.");
			}
		}

		function getCookie(name: string): string | undefined {
			const matches = document.cookie.match(
				new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"),
			);
			return matches ? decodeURIComponent(matches[1]) : undefined;
		}
	}, []);

	const videoSrc =
		"https://www.youtube.com/embed/" +
		getAnchor(src) +
		"?enablejsapi=1&modestbranding=1&controls=1&disablekb=1&rel=0&showinfo=0&fs=1&playsinline=1&color=white" +
		(autoplay ? "&autoplay=1" : "");

	const allowOptions =
		"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";

	const containerClass = `youtube-full-width youtube-cookies ${
		article ? "youtube-article" : modal ? "youtube-modal" : ""
	}`;

	return (
		<div className={containerClass}>
			<iframe
				width="560"
				height="315"
				title={title}
				frameBorder="0"
				allow={allowOptions}
				allowFullScreen
				src={videoSrc}
				className="youtube-full-width__video"
			></iframe>
		</div>
	);
}

export default YouTube;
