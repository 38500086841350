"use client";

import { useState } from "react";
import Link from "next/link";
import Image from "next/image";

import { HeaderMenu } from "./HeaderMenu";

export const Header = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const subMenuOpen = () => {
		setIsMenuOpen(true);
	};

	const subMenuClose = () => {
		setIsMenuOpen(false);
	};

	return (
		<header className="pu-header">
			<div className="pu-header__wrapper">
				<HeaderMenu />
				<div className="pu-header__logo">
					<Link href="/">
						<Image unoptimized width={170} height={39} src="/img/logo-pu-dark.svg" alt="" />
					</Link>
				</div>
				<div className="pu-header__actions">
					<div className="pu-header__panel">
						{/* <div className="pu-header__search">
              <Link
                href="/wyszukiwanie"
                className="pu-btn pu-btn--icon"
                type="button"
              >
                <Image
                  unoptimized
                  width={40}
                  height={40}
                  src={`img/icon-navbar-search.svg`}
                  alt=""
                />
              </Link>
            </div> */}
					</div>
				</div>

				<button
					className="pu-navigation__submenu"
					aria-label="Otwórz panel"
					type="button"
					onClick={subMenuOpen}
				>
					<span className="vertical-dots" />
				</button>

				<div className={`pu-header__mobile ${isMenuOpen ? "active" : ""}`}>
					<button className="pu-header__mobile__close" onClick={subMenuClose}>
						<span className="sr-only">Zamknij mobilne menu</span>
					</button>
				</div>
			</div>
		</header>
	);
};
