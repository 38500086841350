import { useEffect, useMemo, useRef } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { enableBodyScroll } from "body-scroll-lock";

// eslint-disable-next-line
import { NavigationButton } from "../navigationTypes";

interface Props {
	activeContentId: string;
	handleMenuButtonClick: (contentId: string) => void;
	contentFocused: boolean;
	setContentFocused: (value: boolean) => void;
	resetActiveContentId: () => void;
}

export const NavigationMenu = ({
	activeContentId,
	handleMenuButtonClick,
	contentFocused,
	setContentFocused,
	resetActiveContentId,
}: Props) => {
	const pathname = usePathname(); // Get the current pathname
	const buttonRefs = useRef<(HTMLButtonElement | HTMLAnchorElement | null)[]>([]);

	// Wrap navigationItems with useMemo to avoid recreating it on each render
	const navigationItems: NavigationButton[] = useMemo(
		() => [
			{
				type: "link",
				label: "Strona główna",
				href: "/",
			},
			{
				type: "link",
				label: "O produkcie",
				href: "/o-produkcie",
			},
			{
				type: "link",
				label: "O nas",
				href: "/o-nas",
			},
			{
				type: "link",
				label: "Kontakt",
				href: "/kontakt",
			},
		],
		[], // Zależność jest pusta, bo tablica jest statyczna i nie zmienia się
	);

	useEffect(() => {
		buttonRefs.current = buttonRefs.current.slice(0, navigationItems.length);
	}, [navigationItems.length]);

	useEffect(() => {
		if (!contentFocused) {
			const activeButtonIndex = navigationItems.findIndex(
				(button) => button.contentId === activeContentId,
			);

			if (activeButtonIndex !== -1) {
				buttonRefs.current[activeButtonIndex]?.focus();
			}
		}
	}, [activeContentId, contentFocused, navigationItems]);

	const handleLinkClick = (index: number) => {
		resetActiveContentId();
		const currentElement = buttonRefs.current[index];
		if (currentElement instanceof HTMLElement) {
			enableBodyScroll(currentElement);
			document.body.classList.remove("no-scroll");
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
		if (event.key === "ArrowUp" && index > 0) {
			buttonRefs.current[index - 1]?.focus();
			if (navigationItems[index - 1].type === "link") {
				resetActiveContentId();
			} else if (navigationItems[index - 1].type === "content") {
				handleMenuButtonClick(navigationItems[index - 1].contentId || "");
			}
		} else if (event.key === "ArrowDown" && index < navigationItems.length - 1) {
			buttonRefs.current[index + 1]?.focus();
			if (navigationItems[index + 1].type === "link") {
				resetActiveContentId();
			} else if (navigationItems[index + 1].type === "content") {
				handleMenuButtonClick(navigationItems[index + 1].contentId || "");
			}
		} else if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
			if (navigationItems[index].type === "content") {
				setContentFocused(true);
			}
		} else if (event.key === "Enter" && navigationItems[index].type === "link") {
			resetActiveContentId();
			const currentElement = buttonRefs.current[index];
			if (currentElement instanceof HTMLElement) {
				enableBodyScroll(currentElement);
				document.body.classList.remove("no-scroll");
			}
		}
	};

	return (
		<div className="pu-navigation__menu">
			{navigationItems.map((button, index) => {
				const isActive = button.contentId === activeContentId;
				const isLinkActive = button.type === "link" && pathname === button.href;

				if (button.type === "content") {
					return (
						<button
							ref={(ref) => {
								buttonRefs.current[index] = ref;
							}}
							type="button"
							key={button.label}
							className={`pu-navigation__menu__item 
              ${isActive ? "active" : ""}
              ${button.separator ? "separator" : ""}`}
							onClick={() => handleMenuButtonClick(button.contentId || "")}
							onMouseEnter={() => handleMenuButtonClick(button.contentId || "")}
							onKeyDown={(event) => handleKeyDown(event, index)}
							role="tab"
							aria-selected={isActive}
							aria-controls={`content${index}`}
						>
							{button.label}
							{button.separator && <span className="pu-navigation__menu__separator" />}
						</button>
					);
				}
				if (button.type === "link") {
					return (
						<Link
							href={button.href}
							key={button.label}
							ref={(ref) => {
								buttonRefs.current[index] = ref;
							}}
							className={`pu-navigation__menu__item 
              ${isLinkActive ? "active" : ""}
              ${button.separator ? "separator" : ""}`}
							onKeyDown={(event) => handleKeyDown(event, index)}
							onClick={() => handleLinkClick(index)}
						>
							{button.label}
							{button.separator && <span className="pu-navigation__menu__separator" />}
						</Link>
					);
				}
				return null;
			})}
		</div>
	);
};
