import { type KeyboardEvent, useEffect, useRef } from "react";

interface NavigationContentProps {
	activeContentId: string;
	contentFocused: boolean;
	setContentFocused: (value: boolean) => void;
}

const WorthLogging = () => {
	return (
		<div className="pu-navigation__content__login">
			<div></div>
		</div>
	);
};

export const NavigationContent = ({
	contentFocused,
	setContentFocused,
}: NavigationContentProps) => {
	const ref = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (contentFocused) {
			ref.current?.focus();
		}
	}, [contentFocused]);

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === "ArrowLeft") {
			setContentFocused(false);
		}
	};

	return (
		<div className="pu-navigation__content" ref={ref} tabIndex={-1} onKeyDown={handleKeyDown}>
			<WorthLogging />
		</div>
	);
};
