"use client";
import { useCallback, useEffect, useRef, useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { NavigationContent } from "./NavigationContent";
import { NavigationMenu } from "./NavigationMenu";
import { type NavigationButton } from "./navigationTypes";

export const Navigation = () => {
	const navigationItems: NavigationButton[] = [
		{
			type: "link",
			label: "Strona główna",
			href: "/",
		},
		{
			type: "link",
			label: "O produkcie",
			href: "/o-produkcie",
		},
		{
			type: "link",
			label: "O nas",
			href: "/o-nas",
		},
		{
			type: "link",
			label: "Kontakt",
			href: "/kontakt",
		},
	];

	const navRef = useRef<HTMLElement>(null);

	const [isOpen, setIsOpen] = useState(false);
	const [activeContentId, setActiveContentId] = useState(navigationItems[0].contentId || "");
	const [contentFocused, setContentFocused] = useState(false);

	const handleMenuButtonClick = (contentId: string) => {
		setActiveContentId(contentId);
		setContentFocused(false);
	};

	const handleButtonClick = useCallback(() => {
		setIsOpen((prev) => !prev);
		if (navRef.current) {
			if (!isOpen) {
				disableBodyScroll(navRef.current);
				document.body.classList.add("no-scroll");
			} else {
				enableBodyScroll(navRef.current);
				document.body.classList.remove("no-scroll");
			}
		}
	}, [isOpen]);

	const resetActiveContentId = () => {
		setActiveContentId("");
		setContentFocused(false);
	};

	useEffect(() => {
		const currentNav = navRef.current;
		const handleEsc = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				handleButtonClick();
			}
		};

		if (isOpen && currentNav) {
			window.scrollTo(0, 0);
			disableBodyScroll(currentNav);
			document.body.classList.add("no-scroll");
			window.addEventListener("keydown", handleEsc);
		} else if (!isOpen && currentNav) {
			enableBodyScroll(currentNav);
			document.body.classList.remove("no-scroll");
			window.removeEventListener("keydown", handleEsc);
		}

		return () => {
			if (currentNav) {
				enableBodyScroll(currentNav);
			}
			window.removeEventListener("keydown", handleEsc);
		};
	}, [isOpen, handleButtonClick]);

	return (
		<div className="pu-navigation">
			<button
				onClick={handleButtonClick}
				className={`pu-navigation__button ${isOpen ? "active" : ""}`}
				aria-label={isOpen ? "Zamknij menu" : "Otwórz menu"}
				aria-haspopup="true"
				aria-expanded={isOpen}
				aria-controls="pu-navigation"
				type="button"
			>
				<span
					className={`pu-navigation__button__icon pu-navigation__button__icon--squeeze ${
						isOpen ? "is-active" : ""
					}`}
				>
					<span className="pu-navigation__button__icon-box">
						<span className="pu-navigation__button__icon-inner" />
					</span>
				</span>
				<span className="pu-navigation__button__text">Menu</span>
			</button>
			{isOpen && (
				<nav
					ref={navRef}
					className="pu-navigation__nav"
					id="pu-navigation"
					aria-label="Menu nawigacji"
				>
					<div className="pu-navigation__wrapper">
						<NavigationMenu
							activeContentId={activeContentId}
							handleMenuButtonClick={handleMenuButtonClick}
							contentFocused={contentFocused}
							setContentFocused={setContentFocused}
							resetActiveContentId={resetActiveContentId}
						/>
						<NavigationContent
							activeContentId={activeContentId}
							contentFocused={contentFocused}
							setContentFocused={setContentFocused}
						/>
					</div>
				</nav>
			)}
		</div>
	);
};
